import React, { PropsWithChildren } from 'react';
import styles from './ProfileActions.module.scss';
import cn from 'classnames';
import { ProfileActionsProps } from './ProfileActions.interface';

const ProfileActions: React.FC<PropsWithChildren<ProfileActionsProps>> = ({
  children,
  alignDesktop = 'right',
  alignMobile,
  minWidth,
  ...props
}) => (
  <div
    {...props}
    className={cn(styles.ProfileActions, {
      [styles.ProfileActionsRight]: alignDesktop === 'right',
      [styles.ProfileActionsLeft]: alignDesktop === 'left',
      [styles.ProfileActionsMobileFull]: alignMobile === 'full',
      [styles.ProfileActionsBtnMinWidthMobile225]: minWidth === 'mobile-225',
      [styles.ProfileActionsBtnMinWidth140]: minWidth === '140',
      [styles.ProfileActionsBtnMinWidth221]: minWidth === '221',
      [styles.ProfileActionsBtnMinWidth341]: minWidth === '341',
      [styles.ProfileActionsBtnMinWidth380]: minWidth === '380',
      [styles.ProfileActionsBtnMinWidth472]: minWidth === '472',
    })}
  >
    {children}
  </div>
);

export default ProfileActions;
